import { Section, countNestedItems } from '../../duck';
import { Items } from '../items';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Tag,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { Icon } from '@lon/shared/components';

const Activities = ({
  items,
  level = 0,
  sectionId,
}: {
  items?: Section[];
  level: number;
  sectionId?: string;
}) => {
  const countedArrayOfNestedItems = React.useMemo(
    () => items?.map((item) => countNestedItems(item)),
    [items]
  );

  return (
    <Accordion p={0} allowToggle>
      {items?.map((item, index) => {
        const { elementsCount, sectionsCount } =
          countedArrayOfNestedItems?.[index] || {};

        return (
          <AccordionItem
            p={0}
            key={item.identifier}
            _last={{
              borderBottomWidth: 0,
            }}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  p={4}
                  justifyContent="space-between"
                  pl={`${level * 2}rem`}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primary.30',
                    },
                    color: 'blue.500',
                  }}
                  border={0}
                >
                  <Flex gap="4" alignItems="center">
                    <Text as="span" variant="h5" textAlign="left">
                      {item?.metadata?.sectionTitle}
                    </Text>
                    <Flex gap="1">
                      {!!sectionsCount && (
                        <Tag
                          fontSize="14px"
                          variant="counter"
                          border="none"
                          color="primary.700"
                          px="4"
                          whiteSpace="nowrap"
                        >
                          {`${sectionsCount} folders`}
                        </Tag>
                      )}
                      {!!elementsCount && (
                        <Tag
                          fontSize="14px"
                          variant="counter"
                          border="none"
                          color="primary.700"
                          px="4"
                          whiteSpace="nowrap"
                        >
                          {`${elementsCount} activities`}
                        </Tag>
                      )}
                    </Flex>
                  </Flex>
                  <Icon name={isExpanded ? 'chevron-down' : 'chevron-right'} />
                </AccordionButton>
                <AccordionPanel
                  p={0}
                  display="flex"
                  flexDirection="column"
                  border={0}
                >
                  {!!item?.elements?.length && (
                    <Items
                      level={level + 1}
                      items={item?.elements}
                      sectionId={sectionId}
                    />
                  )}
                  {!!item?.sections?.length && (
                    <Activities
                      items={item?.sections}
                      level={level + 1}
                      sectionId={sectionId}
                    />
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default Activities;
