import { Box, Divider } from '@chakra-ui/react';
import React from 'react';
import { useThemeStyleValue } from '@lon/shared/hooks';
import {
  SearchPanel,
  collapsibleSidebarPaddingRight,
} from '@lon/suit/components';
import { FilterTabs } from './components';

const Filters: React.FC = () => {
  return (
    <Box aria-label="Filter" zIndex={2} position="relative">
      <Box p="6" pr={collapsibleSidebarPaddingRight} pb="10">
        <SearchPanel name="scopes" />
      </Box>
      <Divider
        display={useThemeStyleValue('none', 'block')}
        color="white"
        mb={6}
      />
      <FilterTabs />
    </Box>
  );
};

export default Filters;
